import React from "react"
import {StaticImage} from "gatsby-plugin-image";

const logoHeight = 86
const logoWideHeight = 56
const logoWidestHeight = 44
const logoSpacing = "px-2 py-2"

const BundesKulturMedien = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/BKM_Logo_Farbe.jpg"
      alt="Die Beauftragte der Bundesregierung für Kultur und Medien"
      placeholder="blurred"
      height={logoHeight}
      layout="constrained"
    />
  </div>
)

const NeustartKultur = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/Neustart_Kultur.jpg"
      alt="Neustart Kultur"
      placeholder="blurred"
      height={logoHeight}
      layout="constrained"
    />
  </div>
)

const Taz = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/taz_Logo.png"
      alt="TAZ"
      placeholder="blurred"
      height={logoWideHeight}
      layout="constrained"
    />
  </div>
)

const TipBerlin = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/tipBerlin_Logo.png"
      alt="tip Berlin"
      placeholder="blurred"
      height={logoWidestHeight}
      layout="constrained"
    />
  </div>
)

const RbbKultur = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/rbb_kultur.jpg"
      alt="RBB Kultur"
      placeholder="blurred"
      height={logoWidestHeight}
      layout="constrained"
    />
  </div>
)

const KonzerthausBerlin = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/KHB_Blau_4C_L.png"
      alt="Konzerthaus Berlin"
      placeholder="blurred"
      height={logoHeight}
      layout="constrained"
    />
  </div>
)

const FranzHans = () => (
  <div className={logoSpacing}>
    <StaticImage
      src="../images/logos-sponsors/Logo_FH06_crop.png"
      alt="FranzHans06"
      placeholder="blurred"
      height={logoHeight}
      layout="constrained"
    />
  </div>
)


const Logos = () => (
  <section id="logos" className="py-5">
    <div className="container text-center">
      <div className="d-flex justify-content-evenly align-items-center flex-wrap">
        <div>
          <div className="row"><p className="lead text-dark">Gefördert von</p></div>
          <div className="d-flex justify-content-evenly align-items-center flex-wrap">
            <a
              href="https://www.bundesregierung.de/breg-de/bundesregierung/bundeskanzleramt/staatsministerin-fuer-kultur-und-medien"
              target="_blank"><BundesKulturMedien className="logo"/></a>
            <a
              href="https://www.bundesregierung.de/breg-de/bundesregierung/bundeskanzleramt/staatsministerin-fuer-kultur-und-medien/kultur/neustart-kultur-1772990"
              target="_blank"><NeustartKultur className="logo"/></a>
          </div>
        </div>
        <div className="mt-4">
          <div className="row"><p className="lead text-dark">Unsere Partner & Medienpartner</p></div>
          <div className="d-flex justify-content-evenly align-items-center flex-wrap">
            <a href="https://taz.de/" target="_blank"><Taz className="logo"/></a>
            <a href="https://www.tip-berlin.de/" target="_blank"><TipBerlin className="logo"/></a>
            <a href="https://www.rbb-online.de/rbbkultur-magazin/" target="_blank"><RbbKultur className="logo"/></a>
            <a href="https://www.konzerthaus.de/" target="_blank"><KonzerthausBerlin className="logo"/></a>
            <a href="https://franzhans06.de/" target="_blank"><FranzHans className="logo"/></a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Logos