import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import {Seo} from "../components/seo"
import Logos from "../components/logos";
import Concerts from "../components/concerts";
import Wzums from "../components/wzums";

// TODO: Links with Sponsor Logos!
// TODO: Links for Artists!

const Poster = () => (
  <StaticImage
    src="../images/Europe_4xPOSTER-crop-web.jpg"
    alt="Europe United in Songs Poster - 4 Konzerte"
    placeholder="blurred"
  />
)

const IndexPage = () => (
  <Layout>
    <section className="container col-xxl-8 px-4 pb-5 pt-md-5">
      <div className="row flex-md-row-reverse align-items-center g-5 pb-5 pt-3 pt-md-5">
        <div className="col-md-6">
          <p className="text-uppercase text-muted">MGV Walhalla zum Seidlwirt & Neustart Kultur präsentieren:</p>
          <h1 className="text-white display-1 mb-3">Europe - United in Songs</h1>
          <p className="lead text-light">Gesang. Streicher. Hörner. Electronica.<br/>
            <span className="lead fs-3 text-white fw-bold">Musik. Ohne Grenzen!</span></p>
          <div className="d-flex justify-content-start">
            <Link to="#concerts"><button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Konzerte</button></Link>
            <Link to="#wzums" className="mx-2"><button type="button" className="btn btn-outline-primary btn-lg px-4">MGV Wzums</button></Link>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Link to="#concerts"><Poster/></Link>
        </div>
      </div>
    </section>
    <Logos />
    <Concerts />
    <Wzums />
  </Layout>
)

export default IndexPage

export const Head = () => (
  <Seo/>
)
