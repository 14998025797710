import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {StaticImage} from "gatsby-plugin-image";

const WzumSImg = () => (
  <div className="px-1 py-2">
    <StaticImage
      src="../images/artists/WzumS_web.jpg"
      alt="MGV Walhalla zum Seidlwirt"
      placeholder="blurred"
      layout="constrained"
      className="img--hover"
    />
  </div>
)

const Wzums = () => {
  const wzumsData = useStaticQuery(graphql`
  query wzumsQuery {
    wzums: markdownRemark(frontmatter: {slug: {eq: "wzums"}}) {
      html
      frontmatter {
        title
        slug
      }
    }
  }
`)

  return (
    <section id="wzums" className="container bg-black75 p-5 rounded">
      <a id="wzums" />
      <h2 className="display-5">{wzumsData.wzums.frontmatter.title}</h2>
      <WzumSImg />
      <div dangerouslySetInnerHTML={{__html: wzumsData.wzums.html}} className="lead" />
      <Link to="https://www.wzums.com" target="_blank"><button type="button" className="btn btn-outline-light btn-lg">Mehr über den MGV WzumS</button></Link>
    </section>
  )
}

export default Wzums