import React from "react"
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const Concert = (props) => {
  var concertClass
  var imgDirection = ""
  switch(props.slug) {
    case "concert-1":
      concertClass = "imgLeft yellow"
      break
    case "concert-2":
      concertClass = "imgRight blue"
      break
    case "concert-3":
      concertClass = "imgLeft pink"
      break
    case "concert-4":
      concertClass = "imgRight green"
      break
    default:
      concertClass = "imgRight"
  }

  if(concertClass.includes("imgLeft")) {
    imgDirection = "flex-row-reverse"
  }

  return (
    <div className={`concert py-5 ${concertClass}`} id={props.id}>
      <div className={`container mx-auto row align-items-center ${imgDirection}`}>
        <div className="col-md-6">
          <h3 className="display-5">{props.title} <Link to={props.tickets} className="btn btn-outline-danger fw-bold fs-3 my-2 px-3" target="_blank">TICKETS</Link></h3>
          <h3>{props.date}</h3>
          <h3>{props.location}</h3>
          <div dangerouslySetInnerHTML={{__html: props.html}}/>
          <div className="lead">
            <p>Mit:</p>
            <ul>{props.artists.map(artist => (
              <li key={artist}>{artist}</li>
            ))}</ul>
          </div>

        </div>
        <div className="concert-picture col-md-6 text-center">
          <GatsbyImage alt={props.title} image={props.img} className="img--hover"/>
          <Link to={props.tickets} className="btn btn-outline-dark fw-bold fs-5 my-2 px-3 w-100" target="_blank">Jetzt Tickets erwerben 🎟️ </Link>
        </div>
      </div>
    </div>
  )
}

export default Concert