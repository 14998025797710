import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Concert from "./concert";

const Concerts = () => {
  const concertData = useStaticQuery(graphql`
  query concertQuery {
    one: markdownRemark(frontmatter: {slug: {eq: "concert-1"}}) {
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY, HH:mm", locale: "de-DE")
        img
        title
        location
        slug
        artists
        tickets
      }
    }
    oneImg: file(relativePath: {eq: "artists/wiener_horn_web.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    two: markdownRemark(frontmatter: {slug: {eq: "concert-2"}}) {
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY, HH:mm", locale: "de-DE")
        img
        title
        location
        slug
        artists
        tickets
      }
    }
    twoImg: file(relativePath: {eq: "artists/Adelphi_Quartet_crop.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    three: markdownRemark(frontmatter: {slug: {eq: "concert-3"}}) {
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY, HH:mm", locale: "de-DE")
        img
        title
        location
        slug
        artists
        tickets
      }
    }
    threeImg: file(relativePath: {eq: "artists/Benjamin_Geyer.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    four: markdownRemark(frontmatter: {slug: {eq: "concert-4"}}) {
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY, HH:mm", locale: "de-DE")
        img
        title
        location
        slug
        artists
        tickets
      }
    }
    fourImg: file(relativePath: {eq: "artists/Fatma_Said_Marcus_Merkel_portrait.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    socialImg: file(relativePath: {eq: "EUROPE_social.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`)

  console.log(concertData)
  return (
    <section id="concerts" className="py-5">
      <a id="concerts"/>
      <div>
        <div className="container mb-5 p-5 bg-black50">
          <h2 className="display-3">Die Konzertreihe</h2>
          <h3>16 Künstler, 4 Konzerte, 1 Europa</h3>
          <p className="lead">Mit der vier-teiligen Konzertreihe <b>"Europe - United in Songs"</b> laden der <i>MGV
            Walhalla zum Seidlwirt</i> und seine hochkarätigen musikalischen Gäste das Publikum ein, die musikalische
            Vielfalt Europas live zu entdecken und zu erleben. Von Schweden bis Israel von Italien bis in die Ukraine.
            Von der Klassik bis zum Volksliedgut. Mit den Mitteln der Musik stoßen wir einen kulturellen Austausch über
            Grenzen hinweg an und bringen zusammen, was einander nah und fern ist.</p>
          <p className="lead">Die Stücke werden für die verschiedenen Besetzungen "maßgeschneidert" neu arrangiert.<br/>
            Wie bei Walhalla zum Seidlwirt-Konzerten üblich werden die Musikerinnen zudem direkt & humorvoll mit dem
            Publikum sprechen und interagieren.</p>
          <p className="lead">"Europe – United in Songs“ schafft so einen neuen Blick auf Europa und darüber hinaus, der die Gemeinsamkeit
            in der Vielfalt erkennt und feiert.</p>
          <p className="lead"><b>Weltoffen, humanistisch, integrierend!</b></p>
        </div>
        <Concert key={concertData.one.frontmatter.slug} slug={concertData.one.frontmatter.slug}
                 location={concertData.one.frontmatter.location} tickets={concertData.one.frontmatter.tickets}
                 img={concertData.oneImg.childImageSharp.gatsbyImageData} date={concertData.one.frontmatter.date}
                 title={concertData.one.frontmatter.title} artists={concertData.one.frontmatter.artists}
                 html={concertData.one.html}/>
        <Concert key={concertData.two.frontmatter.slug} slug={concertData.two.frontmatter.slug}
                 location={concertData.two.frontmatter.location} tickets={concertData.two.frontmatter.tickets}
                 img={concertData.twoImg.childImageSharp.gatsbyImageData} date={concertData.two.frontmatter.date}
                 title={concertData.two.frontmatter.title} artists={concertData.two.frontmatter.artists}
                 html={concertData.two.html}/>
        <Concert key={concertData.three.frontmatter.slug} slug={concertData.three.frontmatter.slug}
                 location={concertData.three.frontmatter.location} tickets={concertData.three.frontmatter.tickets}
                 img={concertData.threeImg.childImageSharp.gatsbyImageData} date={concertData.three.frontmatter.date}
                 title={concertData.three.frontmatter.title} artists={concertData.three.frontmatter.artists}
                 html={concertData.three.html}/>
        <Concert key={concertData.four.frontmatter.slug} slug={concertData.four.frontmatter.slug}
                 location={concertData.four.frontmatter.location} tickets={concertData.four.frontmatter.tickets}
                 img={concertData.fourImg.childImageSharp.gatsbyImageData} date={concertData.four.frontmatter.date}
                 title={concertData.four.frontmatter.title} artists={concertData.four.frontmatter.artists}
                 html={concertData.four.html}/>
      </div>
    </section>
  )
}


export default Concerts